import { identityHost } from "src/services";
import { call } from "./client-helper";
import { TUser } from "src/types";

const baseUrl = `${identityHost}/api/user`;

export type TSingInRequest = {
  email: string;
  password: string;
};

export type TSingUpRequest = {
  email: string;
  password: string;
  confirmPassword: string;
};

export type TIdentityResponse = {
  token: string;
  newUser: boolean;
  errors: string[];
};

export type TPasswordResetInitRrequest = {
  email: string;
};

export type TPasswordResetFinishRrequest = {
  password: string;
  token: string;
  email: string;
};

export type TSiweBeginResponse = {
  sessionId: string;
  token: string;
};

export type TSiweCompleteRequest = {
  ethAddress: string;
  sessionId: string;
  signature: string;
};

const getUser = (accessToken: string) =>
  call<TUser>({
    path: `${baseUrl}/me`,
    method: "GET",
    accessToken: accessToken,
    withCors: true,
  });

const signIn = (request: TSingInRequest) =>
  call<TIdentityResponse>({
    path: `${baseUrl}/sign-in`,
    method: "POST",
    data: request,
  });

const signUp = (request: TSingUpRequest) =>
  call<TIdentityResponse>({
    path: `${baseUrl}/sign-up`,
    method: "POST",
    data: request,
  });

const passwordResetInit = (request: TPasswordResetInitRrequest) =>
  call<TIdentityResponse>({
    path: `${baseUrl}/reset-password/initiate`,
    method: "POST",
    data: request,
  });

const passwordResetFinish = (request: TPasswordResetFinishRrequest) =>
  call<TIdentityResponse>({
    path: `${baseUrl}/reset-password/complete`,
    method: "POST",
    data: request,
  });

const beginSiwe = (address: string) =>
  call<TSiweBeginResponse>({
    path: `${baseUrl}/begin-siwe/${address}`,
    method: "POST",
  });

const completeSiwe = (request: TSiweCompleteRequest) =>
  call<TIdentityResponse>({
    path: `${baseUrl}/complete-siwe`,
    method: "POST",
    data: request,
  });

export const identityClient = {
  getUser,
  signIn,
  signUp,
  passwordResetInit,
  passwordResetFinish,
  beginSiwe,
  completeSiwe,
};

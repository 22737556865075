import { sleep } from "src/utils";
import { WCProjectId, isProduction, ownHost } from "src/services";
import { Chain, HttpTransport, http } from "viem";
import { mainnet, sepolia } from "viem/chains";
import { injected, walletConnect } from "@wagmi/connectors";
import { TWcDefaultWallets, WcDefaultWallets } from "src/types.enums";
import { TAwaiter } from "src/types";
import { createWeb3Modal, Web3Modal, Web3ModalOptions } from "@web3modal/wagmi";
import { Config, createConfig } from "@wagmi/core";

export const WcChain = isProduction ? mainnet : sepolia;

// export type TErc20ContractType = GetContractReturnType<
//   typeof transferAbi,
//   PublicClient<CustomTransport, typeof WcChain>,
//   WalletClient<CustomTransport, typeof WcChain>
// >;

const getWallet = (): TWcDefaultWallets | null => {
  const params = new URLSearchParams(document.location.search);
  const wallet = params.get("wallet")?.toLocaleLowerCase();

  if (!wallet) return null;

  try {
    return WcDefaultWallets.check(wallet!);
  } catch (error) {
    console.error(`Unknonw default web3 wallet: "${wallet}"`);

    return null;
  }
};

const projectId = WCProjectId;

const wcWalletIds: { [name: string]: string } = {
  trust: "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0",
  metamask: "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
};

type TWagmiConfig = Config<
  readonly [Chain, ...Chain[]],
  {
    [mainnet.id]: HttpTransport;
    [sepolia.id]: HttpTransport;
  }
>;
let _wagmiConfig: TWagmiConfig;
let _modalConfig: Web3ModalOptions<TWagmiConfig> | undefined;
let _web3modal: Web3Modal | null = null;
let _getModalCloseAwaiter: TAwaiter | null = null;

export const getOrCreateEthClient = () => {
  if (_modalConfig !== undefined) {
    return {
      wagmiConfig: _wagmiConfig!,
      modalConfig: _modalConfig!,
    };
  }

  const wallet = getWallet();
  const metadata = {
    name: "Fiorin",
    description: "",
    icons: [],
    url: ownHost, // origin must match your domain & subdomain
  };

  _wagmiConfig = createConfig({
    chains: [WcChain],
    transports: {
      [mainnet.id]: http(),
      [sepolia.id]: http(),
    },
    connectors: [
      walletConnect({ projectId, metadata }),
      injected({ shimDisconnect: true }),
    ],
  });

  _modalConfig = {
    wagmiConfig: _wagmiConfig,
    projectId,
    enableAnalytics: true,
  };

  if (wallet) {
    // _modalConfig.excludeWalletIds = "ALL";
    _modalConfig.featuredWalletIds = [wcWalletIds[wallet]];
  }

  return {
    wagmiConfig: _wagmiConfig!,
    modalConfig: _modalConfig!,
  };
};

export const createWeb3modal = () => {
  if (_web3modal !== null)
    return {
      web3modal: _web3modal!,
      getModalCloseAwaiter: _getModalCloseAwaiter!,
    };

  const { modalConfig } = getOrCreateEthClient();

  _web3modal = createWeb3Modal(modalConfig);

  _getModalCloseAwaiter = () => {
    let closed = false;

    const handler = ({ open }: { open: boolean }) => {
      closed = !open;
    };
    const unsubscribe = _web3modal!.subscribeState(handler);

    return async () => {
      while (true) {
        if (closed) {
          console.log("Close WC modal");
          unsubscribe();
          return;
        }

        await sleep(200);
      }
    };
  };

  return {
    web3modal: _web3modal!,
    getModalCloseAwaiter: _getModalCloseAwaiter!,
  };
};

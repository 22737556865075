import { configure, flow, get, makeAutoObservable, set } from "mobx";
import { configurePersistable } from "mobx-persist-store";
import { BridgeStore } from "./bridgeStore";
import { HistoryStore } from "./historyStore";
import { UserStore } from "./userStore";
import { WalletStore } from "./walletStore";
import { MnemonicStore } from "./mnemonicStore";
import { NotificationStore } from "./notificationStore";
import { EthereumStore } from "./ethereumStore";
import { SignalRStore } from "./signalRStore";
import { TransactionStore } from "./transactionStore";
import { isProduction } from "src/services";
import { isString } from "src/utils";

configure({
  // enforceActions: "never",
  computedRequiresReaction: false,
  reactionRequiresObservable: false,
  observableRequiresReaction: false,
});

configurePersistable({
  storage: window.localStorage,
  stringify: true,
});

export class RootStore {
  bridgeStore!: BridgeStore;
  ethereumStore: EthereumStore;
  historyStore: HistoryStore;
  mnemonicStore: MnemonicStore;
  notificationStore: NotificationStore;
  signalRStore: SignalRStore;
  transactionStore: TransactionStore;
  userStore: UserStore;
  walletStore: WalletStore;

  _lock: number = 0;
  isReady: boolean = false;

  _methodsInProgress: { [name: string]: boolean } = {};

  constructor() {
    this.signalRStore = new SignalRStore(this);
    this.userStore = new UserStore(this);
    this.walletStore = new WalletStore(this);
    this.bridgeStore = new BridgeStore(this);
    this.ethereumStore = new EthereumStore(this);
    this.historyStore = new HistoryStore(this);
    this.notificationStore = new NotificationStore();
    this.mnemonicStore = new MnemonicStore(this);
    this.transactionStore = new TransactionStore(this);

    makeAutoObservable(this, {}, { autoBind: true });
  }

  get isLoading() {
    return this._lock !== 0;
  }

  blockingCall = <R, Args extends any[]>(
    action: (rootStore: RootStore, ...args: Args) => Promise<R>
  ) => {
    const rootStore = this;

    return flow<R, [...Args]>(function* (...args: Args) {
      try {
        rootStore._lock++;

        return yield action(rootStore, ...args);
      } finally {
        rootStore._lock--;
      }
    });
  };

  singleCall = <R, Args extends any[]>(
    methodName: string | ((...args: Args) => string),
    action: (rootStore: RootStore, ...args: Args) => Promise<R>
  ) => {
    const rootStore = this;

    return flow<R, [...Args]>(function* (...args: Args) {
      const key: string = isString(methodName)
        ? methodName
        : methodName(...args);

      if (get(rootStore._methodsInProgress, key)) {
        if (!isProduction) {
          console.warn(`Method "${key}" is executing`);
        }

        return;
      }

      if (!isProduction) {
        console.log(`Start method "${key}"`);
      }

      try {
        set(rootStore._methodsInProgress, key, true);

        return yield action(rootStore, ...args);
      } finally {
        set(rootStore._methodsInProgress, key, false);
        if (!isProduction) {
          console.log(`Finished method "${key}"`);
        }
      }
    });
  };

  _initStores = flow(function* (this: RootStore) {
    yield this.signalRStore.init();
    yield this.userStore.init();
    yield this.walletStore.init();

    yield Promise.allSettled([
      this.bridgeStore.init(),
      this.historyStore.init(),
      this.ethereumStore.init(),
    ]);
  });

  init = this.singleCall(
    "initRootStore",
    flow(function* (rootStore: RootStore) {
      if (rootStore.isReady) return;

      const { _initStores, blockingCall } = rootStore;

      yield blockingCall(_initStores)();

      rootStore.isReady = true;
    })
  );

  makeBlockingCallback = <R>(action: () => Promise<R>) =>
    this.blockingCall(action)();
}

import {
  Address,
  Mnemonic,
  OutPoint,
  P2pkhBuilder,
  P2stasBuilder,
  ScriptType,
  toHexBuffer,
  TokenScheme,
  toUtf8Buffer,
  Transaction,
  TransactionReader,
  Wallet,
} from "dxs-stas-sdk";
import { consigliereClient, TBatchBroadcastInitRequest } from "src/clients";
import { mapValues } from "lodash";
import { decrypt, encrypt, utils } from "micro-aes-gcm";
import { sliceIntoChunks } from "src/utils";

const getWallets = async (mnemonic: Mnemonic, paths: string[]) => {
  const master = Wallet.fromMnemonic(mnemonic.phrase);

  return paths.map((x) => master.derive(x));
};

const encodeMnemonic = async (
  secret: string,
  mnemonic: Mnemonic
): Promise<string> => {
  const encripted = Buffer.from(
    await encrypt(toUtf8Buffer(secret), utils.utf8ToBytes(mnemonic.phrase))
  );
  const encodedMnemonic = encripted.toString("hex");

  return encodedMnemonic;
};

const decodeMnemonic = async (
  secret: string,
  encodedMnemonic: string
): Promise<Mnemonic> => {
  const encripted = toHexBuffer(encodedMnemonic);
  const phraseUtf8Bytes = await decrypt(toUtf8Buffer(secret), encripted);
  const phrase = utils.bytesToUtf8(phraseUtf8Bytes);

  return Mnemonic.fromPhrase(phrase);
};

const getTransactions = async (
  accessToken: string,
  ids: Array<string>
): Promise<Record<string, Transaction>> => {
  const batchCount = 50;
  const result: { [id: string]: Transaction } = {};
  const chunks = sliceIntoChunks(ids, batchCount);

  for (const chunk of chunks) {
    const { error, payload: txs } = await consigliereClient.getTransactions(
      accessToken,
      chunk
    );

    if (error) throw error;

    const batch: { [id: string]: Transaction } = mapValues(txs!, (hex) =>
      TransactionReader.readHex(hex)
    );

    for (const id in batch) {
      result[id] = batch[id];
    }
  }

  return result;
};

const getUtxoSet = async (
  accessToken: string,
  address: string,
  tokenScheme?: TokenScheme
): Promise<OutPoint[]> => {
  const { payload, error } = await consigliereClient.getUtxoSet(accessToken, {
    address,
    tokenId: tokenScheme?.TokenId,
  });

  if (error) throw error;

  let lockingScript = tokenScheme
    ? new P2stasBuilder(
        Address.fromBase58(address),
        tokenScheme.TokenId,
        tokenScheme.Symbol
      ).toBuffer()
    : new P2pkhBuilder(Address.fromBase58(address)).toBuffer();

  return payload!.utxoSet.map(
    (x) =>
      new OutPoint(
        x.txId,
        x.vout,
        lockingScript,
        x.satoshis,
        Address.fromBase58(x.address),
        tokenScheme ? ScriptType.p2stas : ScriptType.p2pkh
      )
  );
};

const getFundingUtxo = async (
  accessToken: string,
  address: string,
  request: TBatchBroadcastInitRequest
): Promise<{ utxo: OutPoint; requestId: string }> => {
  const { payload, error } = await consigliereClient.broadcastBatchInit(
    accessToken,
    request
  );

  if (error) throw error;

  const adr = Address.fromBase58(address);
  const lockingScript = new P2pkhBuilder(adr).toBuffer();
  const { feeTxId, feeVout, requestId } = payload!;

  return {
    utxo: new OutPoint(
      feeTxId,
      feeVout,
      lockingScript,
      request.estimatedFeeSatoshis,
      adr,
      ScriptType.p2pkh
    ),
    requestId,
  };
};

export const walletService = {
  getWallets,
  encodeMnemonic,
  decodeMnemonic,
  getTransactions,
  getUtxoSet,
  getFundingUtxo,
};

import {
  BsvNetowrk,
  BsvTokenId,
  CryptoAssets,
  EvmNetworks,
  TAllNetworks,
  TCryptoAsset,
} from "src/types.enums";
import { capitalize } from "src/utils";

export class CryptoAssetKey {
  constructor(
    public readonly Network: TAllNetworks,
    public readonly Asset: TCryptoAsset
  ) {}

  public getKey = () => CryptoAssetKey.getKey(this.Network, this.Asset);

  public toObject = () => ({
    evmNetwork: this.Network,
    cryptoAsset: this.Asset,
  });

  public isSame = (assetKey: CryptoAssetKey) =>
    assetKey.Network === this.Network && assetKey.Asset === this.Asset;

  public static Build = (networkStr: string, assetStr: string) => {
    const network = EvmNetworks.check(networkStr);
    const asset = CryptoAssets.check(assetStr);

    return new CryptoAssetKey(network, asset);
  };

  public static parse = (str: string) => {
    const segs = str.split("/");

    if (segs.length !== 2) throw Error("Malformed Asset key");

    const network: TAllNetworks =
      segs[0].toLowerCase() === BsvTokenId
        ? BsvNetowrk.check(capitalize(segs[0]))
        : EvmNetworks.check(segs[0]);
    const asset = CryptoAssets.check(segs[1]);

    return new CryptoAssetKey(network, asset);
  };

  public static getKey = (network: TAllNetworks, cryptoAsset: TCryptoAsset) =>
    `${network}/${cryptoAsset}`;
}

import { random } from "lodash";

export const generateRandomNumbers = ({
  count,
  max,
  min,
}: {
  count: number;
  max: number;
  min: number;
}): Set<number> => {
  const genNext = (numbers: Set<number>): Set<number> => {
    if (numbers.size === count) {
      return numbers;
    }

    const number = random(min, max);

    if (numbers.has(number)) return genNext(numbers);

    return genNext(new Set([...Array.from(numbers), number]));
  };

  return genNext(new Set());
};

// most popular answer https://stackoverflow.com/questions/175739/how-can-i-check-if-a-string-is-a-valid-number#answer-175787
export const isNumeric = (str: string) => {
  if (typeof str != "string") return false; // we only process strings!

  return (
    !isNaN(+str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
};

import {
  consigliereClient,
  identityClient,
  TAddContactRequest,
  TApiResponse,
  TIdentityResponse,
  TSingInRequest,
  TSingUpRequest,
} from "src/clients";
import {
  flow,
  get,
  keys,
  makeAutoObservable,
  reaction,
  remove,
  set,
  toJS,
  values,
  when,
} from "mobx";
import {
  clearPersistedStore,
  isHydrated,
  makePersistable,
} from "mobx-persist-store";

import { RootStore } from "./rootStore";
import { AppThemes, custodialWalletSetting, TAppTheme } from "src/types.enums";
import { TContact, TUser } from "src/types";

export class UserStore {
  appTheme!: TAppTheme;
  accessToken?: string;
  _user?: TUser;
  isReady: boolean = false;
  _contacts: { [idx: string]: TContact } = {};

  bountyBannerClose: boolean = false;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    makePersistable(this, {
      name: "UserStore",
      properties: ["accessToken", "bountyBannerClose", "appTheme"],
    });

    reaction(
      () => this.hasUser,
      (hasUser) => {
        if (!hasUser) {
          this._clear();
        }
      }
    );
  }

  private _clear() {
    for (const key in this._contacts) {
      remove(this._contacts, key);
    }
  }

  get hasUser() {
    return this.isReady && Boolean(this._user);
  }

  get User() {
    return this._user!;
  }

  get AccessToken() {
    return this.accessToken!;
  }

  get contactIdxs() {
    return keys(this._contacts);
  }

  get contacts() {
    return values(this._contacts);
  }

  getContact = (idx: string): TContact => get(this._contacts, idx);

  init = flow(function* (this: UserStore) {
    yield when(() => isHydrated(this));
    yield this._getUser(true);

    if (!AppThemes.guard(this.appTheme)) this.appTheme = "dark";

    this.isReady = true;
  });

  singIn = this.rootStore.blockingCall(
    flow(function* (root: RootStore, request: TSingInRequest) {
      const response = yield identityClient.signIn(request);

      return yield root.userStore._handleApiResponse(response);
    })
  );

  singUp = this.rootStore.blockingCall(
    flow(function* (
      rootStore: RootStore,
      request: TSingUpRequest,
      createPk: boolean
    ) {
      const $this = rootStore.userStore;
      const response = yield identityClient.signUp(request);

      const error = yield $this._handleApiResponse(response);

      if (!error && createPk) {
        yield consigliereClient.updateSetting(
          $this.AccessToken,
          custodialWalletSetting,
          "true"
        );
        rootStore.walletStore.settings[custodialWalletSetting] = "true";

        if (error) throw error;

        const {
          walletStore: { createPkAndSave },
        } = rootStore;

        yield createPkAndSave();
      }

      return error;
    })
  );

  signOut = this.rootStore.blockingCall(
    flow(function* (root: RootStore) {
      const $this = root.userStore;

      $this.accessToken = undefined;
      $this._user = undefined;
      $this.bountyBannerClose = false;

      yield clearPersistedStore($this);
    })
  );

  recipientsLoaded = false;

  loadContacts = this.rootStore.singleCall(
    "loadContacts",
    flow(function* (rootStore: RootStore) {
      const $this = rootStore.userStore;

      const { payload } = yield consigliereClient.getContacts(
        $this.AccessToken,
        {
          take: 50,
          skip: 0,
          desc: true,
        }
      );

      if (payload) {
        let inserted = 0;

        for (const row of payload) {
          set($this._contacts, inserted.toString(), row);
          inserted++;
        }
      }

      $this.recipientsLoaded = true;
    })
  );

  addContact = flow(function* (
    this: UserStore,
    { name, address }: TAddContactRequest
  ) {
    const { error, payload } = yield consigliereClient.addContact(
      this.AccessToken,
      { name, address }
    );

    if (!error) {
      const contacts = toJS(this._contacts);
      const ids = Object.keys(contacts);

      set(this._contacts, "0", payload!);

      for (let id of ids) {
        set(this._contacts, (Number.parseInt(id) + 1).toString(), contacts[id]);
      }
    }

    return { error, payload };
  });

  closeBountyBanner = () => (this.bountyBannerClose = true);

  _getUser = flow(function* (this: UserStore, init: boolean = false) {
    let result = false;

    if (this.accessToken) {
      try {
        const { payload }: TApiResponse<TUser> = yield identityClient.getUser(
          this.accessToken
        );

        if (payload) {
          this._user = payload;
          this.accessToken = payload.token;

          result = true;
        } else {
          this.accessToken = undefined;
          this._user = undefined;
        }
      } catch (error) {
        console.error(error);
      }
    }

    return result;
  });

  _setAccessToken = flow<boolean, [accessToken: string]>(function* (
    this: UserStore,
    accessToken: string
  ) {
    this.accessToken = accessToken;

    return yield this._getUser();
  });

  _handleApiResponse = flow<
    TMaybeString,
    [response: TApiResponse<TIdentityResponse>]
  >(function* (
    this: UserStore,
    { payload, error }: TApiResponse<TIdentityResponse>
  ) {
    if (payload?.token) {
      this.accessToken = payload.token;

      yield this._getUser();

      return null;
    }
    const errorsObject = JSON.parse(error);

    if (errorsObject.errors?.length) {
      const errors = errorsObject.errors;

      if (errors[0].description) return errors[0].description;
      else return errors[0];
    }

    if (payload?.errors.length) return payload.errors[0];
    return error;
  });
}

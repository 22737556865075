import {
  flow,
  get,
  makeAutoObservable,
  reaction,
  runInAction,
  set,
  values,
} from "mobx";
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel,
} from "@microsoft/signalr";
import { consigliereHost } from "src/services";
import { RootStore } from "./rootStore";
import { TCryptoAsset, TCurrencyPair, TEvmNetwork } from "src/types.enums";
import { TCompensationsDto, TDepositStatus, TTxCosts } from "src/types";
import { CryptoAssetKey } from "src/models";

export class SignalRStore {
  isReady: boolean = false;
  wsConnected: boolean = false;
  connection: HubConnection;

  txCosts: { [evmNetwork: string]: TTxCosts } = {};
  compensationInfos: {
    [assetKey: string]: TCompensationsDto;
  } = {};

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.connection = new HubConnectionBuilder()
      .withUrl(`${consigliereHost}/ws/app`, {
        accessTokenFactory: () => this.rootStore.userStore.AccessToken,
        withCredentials: false,
      })
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: () => Math.random() * 1000,
      })
      .configureLogging(LogLevel.Critical)
      .build();

    this.connection.onreconnected(this._handleReconnection);
    this.connection.onclose(this._handleConnectionClose);

    document.addEventListener("visibilitychange", this._ensureConnectionState);
    window.addEventListener("focus", this._ensureConnectionState);
  }

  _handleConnectionClose = () => {
    this.wsConnected = false;
  };

  _handleReconnection = this.rootStore.singleCall(
    "_handleReconnection",
    flow(function* (rootStore: RootStore) {
      const {
        walletStore: { loadWallet },
      } = rootStore;
      const $this = rootStore.signalRStore;

      yield loadWallet();
      yield $this.subscribeToGasFeed("Ethereum");
      yield $this.subscribeToGasFeed("Polygon");
      yield $this.subscribeToGasFeed("Tron");
      yield $this.subscribeToEvents();

      $this.subscribeToBountyEvent();

      $this.wsConnected = true;
      yield $this.refreshCompensationInfo();
      yield $this.refreshBalances();
    })
  );

  init = () => {
    if (this.isReady) return;

    reaction(
      () => this.rootStore.userStore.hasUser,
      this._ensureConnectionState
    );

    this.isReady = true;
  };

  getTxCosts = (evmNetwork: TEvmNetwork): TTxCosts =>
    get(this.txCosts, evmNetwork);

  getCompensationInfoDto = (
    evmNetwork: TEvmNetwork,
    cryptoAsset: TCryptoAsset
  ): TCompensationsDto =>
    get(
      this.compensationInfos,
      new CryptoAssetKey(evmNetwork, cryptoAsset).getKey()
    );

  _ensureConnectionState = flow(function* (this: SignalRStore) {
    if (document.visibilityState !== "visible") return;

    const {
      rootStore: {
        userStore: { hasUser },
      },
    } = this;

    if (!hasUser) {
      if (this.connection.state !== HubConnectionState.Disconnected) {
        yield this.connection.stop();

        this.wsConnected = false;
      }
    } else {
      if (
        [
          HubConnectionState.Disconnected,
          HubConnectionState.Disconnecting,
        ].includes(this.connection.state)
      ) {
        yield this.connection.start();
        yield this._handleReconnection();
      }
    }
  });

  refreshBalances = flow(function* (this: SignalRStore) {
    const {
      rootStore: {
        walletStore: { isReady, refreshBalances },
        historyStore: { reload },
      },
    } = this;

    if (isReady) {
      yield refreshBalances();
      yield reload();
    }
  });

  handleTxFound = this.rootStore.singleCall(
    "handleTxFound",
    (rootStore: RootStore) => this.refreshBalances()
  );

  handleDepositStatusChanged = (status: TDepositStatus) => {
    const {
      rootStore: {
        historyStore: { depositStatusUpdate },
      },
    } = this;

    depositStatusUpdate(status);

    // if (
    //   status.confirmations > 0 &&
    //   status.confirmationsRequired === status.confirmations
    // )
    //   BridgeAccount.liquidityProvided = true;
  };

  handleBountyAddressChanged = flow(function* (this: SignalRStore) {
    const {
      rootStore: {
        walletStore: { loadWallet },
      },
    } = this;

    yield loadWallet();

    const {
      rootStore: {
        walletStore: { boutyAccount },
      },
    } = this;

    const address = boutyAccount?.address;

    if (Boolean(address)) {
      this.connection.on("OnTransactionFoundSlim", this.handleTxFound);

      yield this.connection.invoke("SubscribeToTransactionStream", {
        address,
        slim: true,
      });
    }
  });

  private subscribeToBountyEvent = () => {
    this._subscribe("OnBountyAddressChanged", this.handleBountyAddressChanged);
  };

  subscribeToGasFeed = flow(function* (
    this: SignalRStore,
    evmNetwork: TEvmNetwork
  ) {
    const costs: TTxCosts = yield this.connection.invoke<TTxCosts>(
      "GetTxCosts",
      evmNetwork
    );

    if (costs) {
      runInAction(() => {
        set(this.txCosts, costs.evmNetwork, costs);
      });
    }

    this._subscribe("OnGasPriceTick", (costs: TTxCosts) => {
      runInAction(() => {
        set(this.txCosts, costs.evmNetwork, costs);
      });
    });

    yield this.connection.invoke("SubscribeToGasPriceStream", evmNetwork);
  });

  subscribeToTransactionStreams = flow(function* (this: SignalRStore) {
    const {
      rootStore: {
        walletStore: { bsvAccount, boutyAccount },
      },
    } = this;

    this.connection.off("OnTransactionFoundSlim", this.handleTxFound);

    if (bsvAccount || boutyAccount) {
      this.connection.on("OnTransactionFoundSlim", this.handleTxFound);

      if (Boolean(bsvAccount?.address)) {
        yield this.connection.invoke("SubscribeToTransactionStream", {
          address: bsvAccount?.address,
          slim: true,
        });
      }

      if (Boolean(boutyAccount?.address)) {
        yield this.connection.invoke("SubscribeToTransactionStream", {
          address: boutyAccount?.address,
          slim: true,
        });
      }
    }
  });

  subscribeToDepositStatusChangeEvent = () => {
    this.connection.off(
      "OnDepositStatusChanged",
      this.handleDepositStatusChanged
    );

    if (values(this.rootStore.walletStore.evmAccounts).length > 0) {
      this.connection.on(
        "OnDepositStatusChanged",
        this.handleDepositStatusChanged
      );
    }
  };

  handleRateChaged = ({
    rate,
    currencyPair,
  }: {
    rate: number;
    currencyPair: TCurrencyPair;
  }) => {
    if (currencyPair === "BsvUsd") this.rootStore.walletStore.bsvRate = rate;
    if (currencyPair === "BtcUsd") this.rootStore.walletStore.btcRate = rate;
    if (currencyPair === "EthUsd") this.rootStore.walletStore.ethRate = rate;
    if (currencyPair === "TrxUsd") this.rootStore.walletStore.trxRate = rate;
  };

  subscribeToEvents = flow(function* (this: SignalRStore) {
    yield this.subscribeToTransactionStreams();
    this.subscribeToDepositStatusChangeEvent();

    this.connection.off("OnRateChanged", this.handleRateChaged);
    this.connection.on("OnRateChanged", this.handleRateChaged);
  });

  getCompensationInfo = this.rootStore.singleCall<
    unknown,
    [TEvmNetwork, TCryptoAsset]
  >(
    (evmNetwork, cryptoAsset) =>
      `GetCompensationInfo-${evmNetwork}-${cryptoAsset}`,
    flow(function* (
      rootStore: RootStore,
      evmNetwork: TEvmNetwork,
      cryptoAsset: TCryptoAsset
    ) {
      const store = rootStore.signalRStore;

      if (!store.wsConnected) return;

      const info: TCompensationsDto =
        yield store.connection.invoke<TCompensationsDto>(
          "GetCompensationInfo",
          evmNetwork,
          cryptoAsset
        );

      var key = new CryptoAssetKey(evmNetwork, cryptoAsset);
      set(store.compensationInfos, key.getKey(), info);
    })
  );

  refreshCompensationInfo = flow(function* (this: SignalRStore) {
    const {
      rootStore: {
        walletStore: { evmAccounts },
      },
    } = this;

    const accounts = values(evmAccounts);

    for (const evmAccount of accounts) {
      if (evmAccount.evmNetwork !== "Any")
        yield this.getCompensationInfo(
          evmAccount.evmNetwork,
          evmAccount.cryptoAsset
        );
    }
  });

  _subscribe = (methodName: string, method: (...args: any[]) => void) => {
    this.connection.off(methodName, method);
    this.connection.on(methodName, method);
  };
}

import {
  flow,
  get,
  keys,
  makeAutoObservable,
  reaction,
  remove,
  set,
  toJS,
  when,
} from "mobx";
import { consigliereClient, TBsvHistoryItem } from "src/clients";
import { RootStore } from "./rootStore";
import { frameService, isInFrame } from "src/services";
import { TDepositStatus } from "src/types";

export type THistoryListItem = {
  idx: string;
  type: "tx" | "deposit";
};

export class HistoryStore {
  isReady: boolean = false;
  isLoading = false;
  loadCalledOnce = false;

  // _tokenId?: string;
  _totalCount: number = 0;
  offset: number = 0;
  data: { [idx: string]: TBsvHistoryItem } = {};
  bsvDeposits: { [idx: string]: TDepositStatus } = {};

  pendingDeposit?: TDepositStatus;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    reaction(() => this.rootStore.userStore.hasUser, this._onHasUserChanged);
    reaction(() => this.pendingDeposit, this._onPendingDepositChanged);
  }

  get totalCount() {
    const { _totalCount, pendingDeposit } = this;

    if (pendingDeposit) return _totalCount + 1;

    return _totalCount;
  }

  get list(): THistoryListItem[] {
    const { data, pendingDeposit } = this;

    let result: THistoryListItem[] = keys(data).map((x) => ({
      idx: x.toString(),
      type: "tx",
    }));

    if (pendingDeposit) {
      result = [{ idx: "pending", type: "deposit" }, ...result];
    }

    return result;
  }

  getItem = ({
    idx,
    type,
  }: THistoryListItem): TBsvHistoryItem | TDepositStatus => {
    if (type === "tx") return get(this.data, idx);

    return this.pendingDeposit!;
  };

  init = flow(function* (this: HistoryStore) {
    // await when(() => isHydrated(this));

    yield Promise.allSettled([
      when(() => this.rootStore.userStore.isReady),
      when(() => this.rootStore.walletStore.isReady),
      // when(() => this.rootStore.bridgeStore.isReady),
    ]);

    this.isReady = true;
  });

  depositStatusUpdate = (status: TDepositStatus) => {
    if (status.depositSide === "Ethereum") {
      this.pendingDeposit = status;
    } else {
      set(this.bsvDeposits, status.inputTxId, status);
    }
  };

  reload = flow(function* (this: HistoryStore) {
    if (this.isLoading) return;

    const { loadPage, offset } = this;
    const take = 15;

    if (offset === 0) {
      yield loadPage();
      return;
    }

    this.isLoading = true;

    const { _loadBatch, data } = this;
    const currentState = toJS(data);

    let o = 0;
    let k = 0;
    let totalCount = 0;
    const { txId: currenFirstTxId } = currentState["0"];
    const addItems: { idx: string; row: TBsvHistoryItem }[] = [];

    while (true) {
      const page = yield _loadBatch(o, take);
      if (!page) return;

      totalCount = page.totalCount; // TODO Restart if totalCount change during loading
      let stop = false;

      for (let i = 0; i < page.history.length; i++) {
        const row = page.history[i];
        const idx = (o + i).toString();
        const existing = currentState[idx];

        if (!existing) {
          // no more rows loaded
          stop = true;
          break;
        } else {
          if (existing.txId === row.txId) {
            // nothing changed
            stop = true;
            break;
          }
          if (row.txId === currenFirstTxId) {
            // diff found
            stop = true;
            break;
          }

          k++;
          addItems.push({ idx, row });
        }
      }

      if (page.history.length !== take || stop) break;
      o += take;
    }

    if (k > 0) {
      let i = 0;
      for (const { idx, row } of addItems) {
        i++;
        set(this.data, idx, row);
      }

      for (const p in currentState) {
        i++;
        set(this.data, (+p + k).toString(), currentState[p]);
      }

      this._totalCount = totalCount;
      this.offset = i;
    }

    this.isLoading = false;
  });

  loadPage = flow(function* (this: HistoryStore) {
    const {
      isLoading,
      offset,
      _totalCount,
      rootStore: {
        walletStore: { hasBsvAccount },
      },
    } = this;

    if (isLoading || (offset > 0 && offset === _totalCount) || !hasBsvAccount)
      return;

    this.isLoading = true;

    const payload = yield this._loadBatch(offset, 15);

    if (payload) {
      let inserted = 0;

      for (const row of payload.history) {
        set(this.data, (offset + inserted).toString(), row);
        inserted++;
      }

      this._totalCount = payload.totalCount;
      this.offset = offset + inserted;
    }

    this.isLoading = false;
    this.loadCalledOnce = true;
  });

  _loadBatch = flow(function* (
    this: HistoryStore,
    offset: number,
    take: number
  ) {
    const { AccessToken } = this.rootStore.userStore;
    const { BsvAccount, tokenIds } = this.rootStore.walletStore;

    const { payload } = yield consigliereClient.getBsvHistory(AccessToken, {
      desc: true,
      skip: offset,
      take,
      address: BsvAccount.address,
      tokenIds,
      withMeta: true,
      withPendingDeposit: offset === 0,
      skipZeroBalance: true,
    });

    if (offset === 0) {
      this.pendingDeposit = payload?.pendingDeposit ?? undefined;
    }

    return payload;
  });

  _clear() {
    this._totalCount = 0;
    this.offset = 0;
    this.loadCalledOnce = false;

    for (const key in this.data) {
      remove(this.data, key);
    }
  }

  _onHasUserChanged = flow(function* (
    this: HistoryStore,
    hasUser: boolean,
    prevValue: boolean
  ) {
    if (!hasUser && prevValue) yield this._clear();
  });

  _onBalanceChanged = flow(function* (this: HistoryStore) {
    const {
      rootStore: {
        userStore: { hasUser },
        walletStore: { hasBsvAccount },
      },
    } = this;

    if (hasUser && hasBsvAccount) yield this.reload();
  });

  _onPendingDepositChanged = () => {
    if (!isInFrame()) return;

    frameService.sendPendingBalance();
  };
}

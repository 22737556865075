import { Orders, RootStore, rootStore } from "src/store";
import {
  iFrameMasterHost,
  isDevelopment,
  isProduction,
  isStaging,
  liquidityPoolAddress,
  marginPoolAddress,
} from "./config-service";
import { entries, reaction, toJS, when } from "mobx";
import { number, object, string } from "yup";
import getBrowserFingerprint from "get-browser-fingerprint";
import {
  Address,
  TransactionBuilderError,
  TransactionReader,
  sha256,
  toHexBuffer,
  toUtf8Buffer,
} from "dxs-stas-sdk";
import { history, routes } from "./routes-service";
import { getGameAddress } from "src/clients/dxs-client";
import { sleep } from "src/utils";
import { decrypt, encrypt, utils } from "micro-aes-gcm";
import { walletService } from "./wallet-service";
import { BsvTokenId, TCryptoAsset } from "src/types.enums";

type SelfMessageEvent = MessageEvent<{
  event: IframeMasterEvent;
  payload: unknown;
}>;

export enum IframeSelfEvent {
  Ready = "READY",
  LoginRequired = "LOGIN_REQUIRED",
  LoginFailed = "LOGIN_FAILED",
  AuthRequired = "AUTH_REQUIRED",
  Authorized = "AUTHORIZED",
  Declined = "DECLINED",
  Closed = "CLOSED",
  TransactionSent = "REFILL_COMPLETED",
  LogoutCompleted = "LOGOUT_COMPLETED",
  Balance = "BALANCE",
  BountyBalance = "BOUNTY_BALANCE",
  PendingBalance = "PENDING_BALANCE",
  Address = "ADDRESS",
  Token = "TOKEN",
  Error = "ERROR",
  OpenMe = "OPEN_ME",
  Store = "STORE",
  OpenLink = "OPEN_LINK",
  IdentityProvider = "IDENTITY_PROVIDER",
  withdrawal = "WITHDRAWAL",
  deposit = "DEPOSIT",
  ActiveTokens = "ACTIVE_TOKENS",
}

export enum IframeMasterEvent {
  SendTransaction = "REFILL",
  Logout = "LOGOUT",
  GetBalance = "GET_BALANCE",
  GetBountyBalance = "GET_BOUNTY_BALANCE",
  GetPendingBalance = "GET_PENDING_BALANCE",
  GetToken = "GET_TOKEN",
  GetAddress = "GET_ADDRESS",
  Topup = "TOPUP",
  ViewDeposit = "VIEW:DEPOSIT",
  ViewWallet = "VIEW:WALLET",
  Store = "STORE",
  GetIdentityProvider = "GET_IDENTITY_PROVIDER",
  ListActiveTokens = "LIST_ACTIVE_TOKENS",
}

export enum SendToPool {
  MarginPool = "ACCOUNT",
  Liquiditypool = "POOL",
  Gtp = "GTP",
}

const sendRequestPayloadSchema = object().shape({
  amount: number().required(),
  tokenId: string(),
  message: string().required(),
  type: string()
    .required()
    .oneOf(
      Object.values(SendToPool),
      `Type must be one of: [${Object.values(SendToPool).join(", ")}]`
    ),
  gameId: number().test({
    name: "gameId",
    message: "GameId must be specified",
    test: (value, testContext) => {
      return testContext.parent.type === SendToPool.Gtp
        ? value !== undefined
        : true;
    },
  }),
});

export const isInFrame = () => window.self !== window.top;

const windowOpen = window.open;

window.open = (
  url?: string | URL,
  target?: string,
  features?: string
): WindowProxy | null => {
  if (!isInFrame()) return windowOpen(url, target, features);

  frameService.openLink(url, target, features);

  return null;
};

type TBalanceMessage = {
  tokenId: string;
  amount: number;
  amountUsd: number;
  currency: TCryptoAsset;
  name: string;
  pendingAmount?: number;
  pendingAmountUsd?: number;
};

// TODO [Oleg] Must be store not service
export class FrameService {
  constructor(private rootStore: RootStore) {
    this.init();
  }

  close = () => {
    this.sendMessage(IframeSelfEvent.Closed);
  };

  decline = () => {
    this.sendMessage(IframeSelfEvent.Declined);
  };

  openMe = () => {
    this.sendMessage(IframeSelfEvent.OpenMe);
  };

  openLink = (url?: string | URL, target?: string, features?: string) => {
    this.sendMessage(IframeSelfEvent.OpenLink, { url, target, features });
  };

  sendPendingBalance = () => {
    const pendingBalance =
      this.rootStore.historyStore.pendingDeposit?.amount ?? 0;

    this.sendMessage(IframeSelfEvent.PendingBalance, { pendingBalance });
  };

  withdrawal = (amount: number) => {
    this.sendMessage(IframeSelfEvent.withdrawal, { amount });
  };

  deposit = (amount: number) => {
    this.sendMessage(IframeSelfEvent.deposit, { amount });
  };

  error = (message: string) => {
    this.sendMessage(IframeSelfEvent.Error, {
      message,
    });
  };

  private init = async () => {
    if (!isInFrame()) return;

    when(
      () => this.rootStore.isReady,
      async () => {
        const {
          rootStore: { isReady },
        } = this;

        if (!isReady) return;

        window.addEventListener("message", this.handleMessage);

        this.sendMessage(IframeSelfEvent.Ready);
        this.ensureHasAccess("INIT");

        when(
          () => this.rootStore.walletStore.hasPk,
          () => {
            when(
              () => this.rootStore.walletStore.dxsAuthorized,
              async () => {
                const {
                  rootStore: {
                    userStore: { hasUser },
                    walletStore: { dxsAuthorized },
                  },
                } = this;

                if (!hasUser || !dxsAuthorized) return;

                const {
                  rootStore: {
                    userStore: {
                      User: { email },
                    },
                    walletStore: { client },
                  },
                } = this;

                this.sendMessage(IframeSelfEvent.Authorized, {
                  email: client ? client.handle : email,
                  username: email,
                });

                await this.trySendStorePacket();

                this.sendNewBalance();
                this.listActiveTokens();

                reaction(
                  () => this.rootStore.walletStore.bountyBalance,
                  () => this.sendBountyBalanceMessage()
                );

                reaction(
                  () => this.rootStore.walletStore.balancesChanged,
                  () => {
                    this.sendNewBalance();
                    this.listActiveTokens();
                  }
                );
              }
            );
          }
        );
      }
    );
  };

  private sendNewBalance = () => {
    const {
      rootStore: {
        walletStore: {
          tokenSchemes,
          tokenWithMaxBalance: { tokenId, balance },
          getInUsd,
        },
      },
    } = this;

    const message: TBalanceMessage = {
      tokenId,
      amount: balance,
      amountUsd: getInUsd(tokenId, balance),
      name: tokenSchemes[tokenId].tokenScheme.Name,
      currency: tokenSchemes[tokenId].cryptoAssetKey.Asset,
    };

    this.sendBalanceMessage(message);
  };

  private ensureHasAccess = (from: string): boolean => {
    const {
      rootStore: {
        userStore: { AccessToken },
        walletStore: { hasPk, dxsAuthorized },
      },
    } = this;

    if (!toJS(AccessToken) || !toJS(hasPk)) {
      this.sendMessage(IframeSelfEvent.LoginRequired, from);

      return false;
    }

    if (!toJS(dxsAuthorized)) {
      this.sendMessage(IframeSelfEvent.AuthRequired, from);

      return false;
    }

    return true;
  };

  private handleMessage = async ({
    data: { event, payload },
  }: SelfMessageEvent) => {
    if (!isProduction) {
      console.log({ event, payload });
    }

    if (event === IframeMasterEvent.Store) {
      await this.tryGetStorePacket(`${payload}`);

      return;
    }

    if (!this.ensureHasAccess(event)) return;

    try {
      switch (event) {
        case IframeMasterEvent.Logout:
          const {
            rootStore: {
              userStore: { signOut },
            },
          } = this;

          await signOut();
          await sleep(500);

          this.sendMessage(IframeSelfEvent.LogoutCompleted);

          break;
        case IframeMasterEvent.GetToken:
          const {
            rootStore: {
              userStore: { AccessToken },
            },
          } = this;

          this.sendMessage(IframeSelfEvent.Token, {
            token: toJS(AccessToken),
          });

          break;

        case IframeMasterEvent.GetBalance:
          {
            const {
              rootStore: {
                walletStore: { refreshBalances },
              },
            } = this;

            await refreshBalances();

            const {
              rootStore: {
                walletStore: { tokenSchemes, getTokenId },
                historyStore: { pendingDeposit },
              },
            } = this;

            const pendingTokenId = pendingDeposit
              ? getTokenId(
                  pendingDeposit.evmNetwork,
                  pendingDeposit.cryptoAsset
                ).tokenId
              : undefined;

            // tokenIds.forEach((tokenId) => {
            //   const message: TBalanceMessage = {
            //     tokenId,
            //     amount: getBalance(tokenId),
            //     name: tokenSchemes[tokenId].tokenScheme.Name,
            //   };

            //   if (pendingDeposit && tokenId === pendingTokenId) {
            //     message.pendingAmount = pendingDeposit.amount;
            //   }

            //   this.sendBalanceMessage(message);
            // });

            const {
              rootStore: {
                walletStore: {
                  tokenWithMaxBalance: { tokenId, balance },
                  getInUsd,
                },
              },
            } = this;

            const message: TBalanceMessage = {
              tokenId,
              amount: balance,
              amountUsd: getInUsd(tokenId, balance),
              name: tokenSchemes[tokenId].tokenScheme.Name,
              currency: tokenSchemes[tokenId].cryptoAssetKey.Asset,
            };
            if (pendingDeposit && tokenId === pendingTokenId) {
              message.pendingAmount = pendingDeposit.amount;
              message.pendingAmountUsd = getInUsd(
                tokenId,
                pendingDeposit.amount!
              );
            }

            this.sendBalanceMessage(message);

            this.sendBountyBalanceMessage();
          }

          break;

        case IframeMasterEvent.GetBountyBalance:
          {
            const {
              rootStore: {
                walletStore: { refreshBalances },
              },
            } = this;

            await refreshBalances();

            this.sendBountyBalanceMessage();
          }
          break;

        case IframeMasterEvent.GetPendingBalance:
          this.sendPendingBalance();
          break;

        case IframeMasterEvent.SendTransaction:
          await this.sendTransaction(payload);

          break;

        case IframeMasterEvent.GetAddress:
          const {
            rootStore: {
              walletStore: { BsvAccount },
            },
          } = this;

          this.sendMessage(IframeSelfEvent.Address, {
            address: BsvAccount.address,
          });

          break;

        case IframeMasterEvent.Topup:
        case IframeMasterEvent.ViewDeposit:
          history.push(routes.wallet.money.uri("deposit"));

          await sleep(100);
          this.openMe();

          break;

        case IframeMasterEvent.ViewWallet:
          history.push(routes.wallet.path);

          await sleep(100);
          this.openMe();

          break;

        case IframeMasterEvent.GetIdentityProvider:
          const { isEthereumAccount } = this.rootStore.walletStore;

          this.sendMessage(IframeSelfEvent.IdentityProvider, {
            provider: isEthereumAccount ? "Ethereum" : "Identity",
          });

          break;

        case IframeMasterEvent.ListActiveTokens:
          this.listActiveTokens();
          break;
      }
    } catch (error) {
      this.sendMessage(IframeSelfEvent.Error, {
        message: (error as Error).message,
      });
    }
  };

  private listActiveTokens = () => {
    const {
      walletStore: { getBalance, getInUsd, tokenSchemes, bountyTokenId },
    } = this.rootStore;

    let tokens = entries(tokenSchemes)
      .map(([tokenId, { cryptoAssetKey, tokenScheme }]) => ({
        tokenId,
        name: tokenScheme.Name,
        amount: getBalance(tokenId),
        amountUsd: getInUsd(tokenId, getBalance(tokenId)),
        currency: cryptoAssetKey.Asset,
        order: Orders[cryptoAssetKey.Network][cryptoAssetKey.Asset],
      }))
      .sort((a, b) => a.order - b.order)
      .filter((x) => x.currency !== "Usdxs" || x.amount > 0)
      .filter((x) => x.tokenId !== bountyTokenId);

    this.sendMessage(IframeSelfEvent.ActiveTokens, {
      tokens,
    });

    // const bountyMessage = this.buildBountyBalanceMessage();

    // if (bountyMessage.amount > 0) {
    //   tokens.push(bountyMessage);
    // }
  };

  inProgress = false;
  private sendTransaction = async (payload: unknown) => {
    if (this.inProgress) {
      this.sendMessage(IframeSelfEvent.Error, { message: "Double sending" });

      return;
    }

    this.inProgress = true;

    try {
      const { amount, tokenId, message, type, gameId } =
        await sendRequestPayloadSchema.validate(payload);

      const {
        rootStore: {
          walletStore: { getTokenScheme },
        },
      } = this;

      const { tokenScheme } = getTokenScheme(tokenId || BsvTokenId);

      const minAmount = 1 / tokenScheme.SatoshisPerToken;

      if (amount < minAmount) {
        throw Error(`Amount must be greater or equal to ${minAmount}`);
      }

      const {
        rootStore: {
          userStore: { AccessToken },
        },
      } = this;

      const addressStr =
        type === SendToPool.MarginPool
          ? marginPoolAddress
          : type === SendToPool.Liquiditypool
          ? liquidityPoolAddress
          : type === SendToPool.Gtp
          ? (await getGameAddress(AccessToken, gameId!)).payload ?? ""
          : "";

      const address = Address.fromBase58(addressStr);
      const {
        rootStore: {
          transactionStore: {
            prepareBsvTransaction,
            prepareStasBundle,
            broadcast,
            setBundleToSend,
            setTransactionToSend,
          },
        },
      } = this;

      const note = message
        ? message.split(" ").map((x: string) => Buffer.from(x, "utf8"))
        : undefined;
      let txRaw: string;

      if (tokenId !== BsvTokenId) {
        const bundle = await prepareStasBundle(tokenId!, amount, address, note);

        if (bundle.message) {
          throw new Error(
            isDevelopment || isStaging ? bundle.devMessage : bundle.message
          );
        }

        setBundleToSend(bundle);

        txRaw = bundle.transactions![bundle.transactions!.length - 1];
      } else {
        const tx = await prepareBsvTransaction(amount, address, note);
        txRaw = tx.toHex();

        setTransactionToSend(tx);
      }

      await broadcast(tokenId);

      const tx = TransactionReader.readHex(txRaw);
      this.sendMessage(IframeSelfEvent.TransactionSent, {
        txId: tx.Id,
        // fee: bundle.feeSatoshis, // TODO [Oleg] Ensure no one use it
        amount,
        address: address.Value,
        txs: [tx.Id],
      });
    } catch (error) {
      let message = "";

      if (error instanceof TransactionBuilderError) {
        if (isDevelopment || isStaging) {
          message = error.devMessage;
        } else {
          message = error.message;
        }
      } else {
        message = error instanceof Error ? error.message : `${error}`;
      }

      this.sendMessage(IframeSelfEvent.Error, {
        message,
      });
    }

    this.inProgress = false;
  };

  private getBrowserSecret = () =>
    sha256(toUtf8Buffer(getBrowserFingerprint().toString()));

  private trySendStorePacket = async () => {
    // only for browsers which support stupid storage access API (safari)
    if (!Boolean(document.requestStorageAccess)) return;

    const {
      rootStore: {
        userStore: { accessToken },
        walletStore: { _encoded },
      },
    } = this;

    const secret = this.getBrowserSecret();
    const message = JSON.stringify({
      accessToken,
      pk: _encoded,
    });

    const encripted = Buffer.from(
      await encrypt(secret, utils.utf8ToBytes(message))
    );
    const payload = encripted.toString("hex");

    this.sendMessage(IframeSelfEvent.Store, payload);
  };

  private tryGetStorePacket = async (payload: string) => {
    // only for browsers which support stupid storage access API (safari)
    if (!Boolean(document.requestStorageAccess)) return;

    const {
      rootStore: {
        makeBlockingCallback,
        userStore: { hasUser, _setAccessToken },
        walletStore: { setPk },
      },
    } = this;

    if (hasUser) return;
    if (!payload) return;

    await makeBlockingCallback(async () => {
      try {
        const browserSecret = this.getBrowserSecret();
        const phraseUtf8Bytes = await decrypt(
          browserSecret,
          toHexBuffer(payload)
        );
        const message = utils.bytesToUtf8(phraseUtf8Bytes);
        const { accessToken, pk } = JSON.parse(message);

        if (await _setAccessToken(accessToken)) {
          try {
            await this.rootStore.walletStore.loadWallet();
            await when(() => this.rootStore.walletStore.walletLoaded);

            const mnemonic = await walletService.decodeMnemonic(
              this.rootStore.walletStore.secret,
              pk
            );

            await setPk(mnemonic);
            await sleep(1000);
          } catch {
            this.error("Validate mnemonic");
          }
        }
      } catch (e) {
        this.error(e instanceof Error ? e.message : `${e}`);
      }
    });
  };

  private sendMessage = (event: IframeSelfEvent, payload: unknown = {}) => {
    if (!isInFrame()) return;

    window.parent.postMessage({ event, payload }, iFrameMasterHost);
  };

  private sendBalanceMessage = (message: TBalanceMessage) => {
    this.sendMessage(IframeSelfEvent.Balance, message);
  };

  private buildBountyBalanceMessage = (): TBalanceMessage => {
    const {
      rootStore: {
        walletStore: { bountyTokenId, bountyBalance, toAmount },
      },
    } = this;

    const amount = toAmount(bountyTokenId, bountyBalance);
    return {
      tokenId: bountyTokenId,
      name: "Bounty",
      amount,
      amountUsd: amount,
      currency: "Usdt",
    };
  };

  private sendBountyBalanceMessage = () => {
    this.sendMessage(
      IframeSelfEvent.BountyBalance,
      this.buildBountyBalanceMessage()
    );
  };
}

export const frameService = new FrameService(rootStore);

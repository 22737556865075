import { createContext, FC, PropsWithChildren, useContext } from "react";
import {
  BridgeStore,
  EthereumStore,
  HistoryStore,
  MnemonicStore,
  NotificationStore,
  RootStore,
  TransactionStore,
  UserStore,
  WalletStore,
} from "src/store";
import { SignalRStore } from "./stores/signalRStore";

export const rootStore = new RootStore();
const stores = {
  bridgeStore: rootStore.bridgeStore,
  ethereumStore: rootStore.ethereumStore,
  historyStore: rootStore.historyStore,
  mnemonicStore: rootStore.mnemonicStore,
  notificationStore: rootStore.notificationStore,
  rootStore: rootStore,
  signalRStore: rootStore.signalRStore,
  transactionStore: rootStore.transactionStore,
  userStore: rootStore.userStore,
  walletStore: rootStore.walletStore,
};

export const StoreContext = createContext<{
  bridgeStore: BridgeStore;
  ethereumStore: EthereumStore;
  historyStore: HistoryStore;
  mnemonicStore: MnemonicStore;
  notificationStore: NotificationStore;
  rootStore: RootStore;
  signalRStore: SignalRStore;
  transactionStore: TransactionStore;
  userStore: UserStore;
  walletStore: WalletStore;
}>(stores);

export const StoreProvider: FC<PropsWithChildren> = ({ children }) => (
  <StoreContext.Provider value={stores}>{children}</StoreContext.Provider>
);

export const useStores = () => useContext(StoreContext);

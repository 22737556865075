export type TEnvironment = "development" | "staging" | "production";

const ensureHasValue = (name: string) => {
  const value = process.env[name];

  if (value === undefined) throw Error(`${name} must be set`);

  return value;
};

const ensureHasIntValue = (name: string) => {
  const value = process.env[name];

  if (value === undefined) throw Error(`${name} must be set`);

  return parseInt(value);
};

export const iFrameMasterHost = ensureHasValue("REACT_APP_IFRAME_MASTER_HOST");
export const identityHost = ensureHasValue("REACT_APP_IDENTITY_HOST");
export const consigliereHost = ensureHasValue("REACT_APP_CONSIGLIERE_HOST");
export const dxsApiHost = ensureHasValue("REACT_APP_DXS_API_HOST");
export const ownHost = ensureHasValue("REACT_APP_OWN_HOST");
export const etherscanHost = ensureHasValue("REACT_APP_ETH_SCANNER");
export const maticHost = ensureHasValue("REACT_APP_MATIC_SCANNER");
export const tronHost = ensureHasValue("REACT_APP_TRON_SCANNER");
export const marginPoolAddress = ensureHasValue(
  "REACT_APP_MARGIN_POOL_ADDRESS"
);
export const liquidityPoolAddress = ensureHasValue(
  "REACT_APP_LIQUIDITY_POOL_ADDRESS"
);
export const Environment = ensureHasValue(
  "REACT_APP_ENVIRONMENT"
) as TEnvironment;
export const EthChainId = ensureHasValue("REACT_APP_ETH_CHAIN_ID");
export const WCProjectId = ensureHasValue("REACT_APP_WC_PROJECT_ID");
export const UsdtContractAddress = ensureHasValue(
  "REACT_APP_USDT_CONTRACT_ADDRESS"
) as THexString;
export const UsdcContractAddress = ensureHasValue(
  "REACT_APP_USDC_CONTRACT_ADDRESS"
) as THexString;
export const DaiContractAddress = ensureHasValue(
  "REACT_APP_DAI_CONTRACT_ADDRESS"
) as THexString;

export const BridgeCooldown = ensureHasIntValue("REACT_APP_BRIDGE_COOLDOWN");

export const isDevelopment = Environment === "development";
export const isStaging = Environment === "staging";
export const isProduction = Environment === "production";

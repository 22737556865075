import {
  consigliereClient,
  TApiResponse,
  TAvailableBalanceResponse,
} from "src/clients";
import {
  flow,
  get,
  keys,
  makeAutoObservable,
  reaction,
  set,
  toJS,
  when,
} from "mobx";
import { RootStore } from "./rootStore";
import { makePersistable } from "mobx-persist-store";
import { getTimeSpan, TTimeSpan } from "src/utils/time-utils";
import { BridgeCooldown } from "src/services";
import { BsvTokenId, TCryptoAsset, TEvmNetwork } from "src/types.enums";

export class BridgeStore {
  isReady: boolean = false;

  availableBalances: {
    [tokenId: string]: TAvailableBalanceResponse;
  } = {};
  countdowns: {
    [tokenId: string]: TTimeSpan;
  } = {};
  shouldUpdate: {
    [tokenId: string]: boolean;
  } = {};

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    makePersistable(this, {
      name: "BridgeStore",
      properties: ["availableBalances"],
    });

    reaction(() => this.rootStore.userStore.hasUser, this._onHasUserChanged);
    // reaction(
    //   () => this.rootStore.walletStore.hasPk,
    //   async (hasPk, prevValue) => {
    //     if (!hasPk && Boolean(prevValue)) {
    //       await this._clear();
    //     }
    //   }
    // );
  }

  getLimits = (tokenId: string): TAvailableBalanceResponse => {
    const limit: TAvailableBalanceResponse | undefined = get(
      this.availableBalances,
      tokenId
    );

    return (
      limit || {
        amount: 0,
        withdrawLimit: 0,
        withdrawPeriodStartedSeconds: 0,
      }
    );
  };

  getCountdown = (tokenId: string): TTimeSpan | undefined =>
    get(this.countdowns, tokenId);

  get maxAvailable() {
    let tokenId: string | undefined;
    let stats: TAvailableBalanceResponse | undefined;

    keys(this.availableBalances).forEach((x) => {
      const available = toJS(this.availableBalances[x.toString()]);

      if (!available?.amount) return;

      if (!stats || stats.amount < available?.amount) {
        tokenId = x.toString();
        stats = available;
      }
    });

    return { tokenId, stats };
  }

  init = flow(function* (this: BridgeStore) {
    yield when(() => this.rootStore.userStore.isReady);
    yield when(() => this.rootStore.walletStore.isReady);

    const {
      rootStore: {
        walletStore: { getCryptoAssetKey, tokenIds },
      },
    } = this;

    tokenIds.forEach((tokenId) => {
      const { Asset } = getCryptoAssetKey(tokenId);

      if (tokenId === BsvTokenId || Asset === "Usdxs") return;

      setInterval(() => this._updateCountdown(tokenId), 1000);
    });

    this.isReady = true;
  });

  _updateCountdown = flow(function* (this: BridgeStore, tokenId: string) {
    const {
      availableBalances,
      getAvailableBalance,
      rootStore: {
        walletStore: { getCryptoAssetKey, getEvmAccount },
      },
    } = this;
    const evmAccount = getEvmAccount(tokenId);

    if (!evmAccount) return;

    let countdown: TTimeSpan | undefined = undefined;
    const { Asset, Network } = getCryptoAssetKey(tokenId);

    if (Network === "Bsv" || Asset === "Usdxs") return;

    const balance: TAvailableBalanceResponse = get(availableBalances, tokenId);

    if (balance) {
      const started = new Date(
        balance.withdrawPeriodStartedSeconds * 1000
      ).getTime();
      const endsAt = started + BridgeCooldown;
      const now = new Date().getTime();
      const diff = Math.max(endsAt - now, 0);

      countdown = getTimeSpan(diff);

      if (diff > 0) {
        set(this.shouldUpdate, tokenId, true);
      } else {
        if (get(this.shouldUpdate, tokenId)) {
          yield getAvailableBalance(Network, Asset);

          set(this.shouldUpdate, tokenId, false);
        }
      }
    }

    set(this.countdowns, tokenId, countdown);
  });

  getAvailableBalance = this.rootStore.singleCall(
    (evmNetwork, cryptoAsset) =>
      `getAvailableBalance-${evmNetwork}-${cryptoAsset}`,
    flow(function* (
      rootStore: RootStore,
      evmNetwork: TEvmNetwork,
      cryptoAsset: TCryptoAsset
    ) {
      const {
        userStore: { AccessToken },
        walletStore: { getTokenId },
        bridgeStore: $this,
      } = rootStore;

      const { tokenId } = getTokenId(evmNetwork, cryptoAsset);
      const { payload }: TApiResponse<TAvailableBalanceResponse> =
        yield consigliereClient.getAvailableBalance(
          AccessToken,
          evmNetwork,
          cryptoAsset
        );

      set($this.availableBalances, tokenId, payload);
    })
  );

  _onHasUserChanged = (hasUser: boolean, prevValue: boolean) => {
    if (!hasUser && prevValue) {
      this.availableBalances = {};
    }
  };
}

import { RootStore } from "./rootStore";
import {
  IReactionDisposer,
  flow,
  makeAutoObservable,
  values,
  when,
} from "mobx";
import {
  errorCodes,
  isArrayOfStrings,
  isJsonRpcError,
  isTransactionExecutionError,
  unwrapError,
} from "src/utils";
import {
  WcChain,
  createWeb3modal,
  getOrCreateEthClient,
  isInFrame,
  routes,
} from "src/services";
import {
  TApiResponse,
  TIdentityResponse,
  TSiweBeginResponse,
  consigliereClient,
  identityClient,
} from "src/clients";

import { getAccount, getChainId, signMessage, switchChain } from "@wagmi/core";
import { custodialWalletSetting } from "src/types.enums";

enum State {
  idle = "idle",
  pending = "pending",
  success = "success",
  rejected = "rejected",
  error = "error",
}

type TEmitterEvent = {
  accounts?: readonly `0x${string}`[] | undefined;
  chainId?: number | undefined;
} & {
  uid: string;
};

export class EthereumStore {
  connectedChainId: number | null = null;
  address: string | undefined;
  state: State = State.idle;

  _flowInProgress: string | null = null;

  _amountToDeposit: number | null = null;
  _balance: number | null = null;

  _networkChanged: boolean = false;

  hasProvider: boolean = false;
  isProviderConnected: boolean = false;
  isInjectedProvider: boolean = false;
  signingSiweToken: boolean = false;
  // contract: TErc20ContractType | undefined;
  ethereumView: boolean = false;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  init = () => {
    this.ethereumView =
      isInFrame() &&
      window.location.pathname.startsWith(routes.root.ethereumConnect.path);
  };

  get isConnected() {
    return this.address !== undefined && this.isProviderConnected;
  }

  get isWrongChain() {
    return (
      this.connectedChainId !== null && this.connectedChainId !== WcChain.id
    );
  }

  get isAnotherAddressSelected(): boolean {
    return (
      // this.rootStore.bridgeStore.bridgeAccount !== undefined &&
      // this.rootStore.bridgeStore.bridgeAccount.ethWithdrawAddress !== null &&

      // for test purposes
      this.address !== undefined
      // &&
      // this.rootStore.bridgeStore.bridgeAccount.ethWithdrawAddress.toLowerCase() !==
      //   this.address.toLowerCase()
    );
  }

  get amountToDeposit() {
    return this._amountToDeposit;
  }

  setAmountToDeposit = (amount: number | null) => {
    this._amountToDeposit = amount;
  };

  get balance() {
    return this._balance ?? 0;
  }

  _ensureConnectedFlow = this.rootStore.singleCall(
    "_ensureConnectedFlow",
    flow(function* (rootStore: RootStore) {
      const $this = rootStore.ethereumStore;

      try {
        $this._flowInProgress = "connectFlow";

        if (!$this.isProviderConnected) {
          const { wagmiConfig } = getOrCreateEthClient();

          if (wagmiConfig.state.status !== "connected") {
            const { web3modal, getModalCloseAwaiter } = createWeb3modal();

            yield web3modal.open();
            yield getModalCloseAwaiter()();
          }

          const account = getAccount(wagmiConfig);

          if (account.status === "disconnected") return;

          const connector = account.connector;

          $this.isInjectedProvider = connector?.id === "injected";

          $this._attachHandlers();

          $this.isProviderConnected = true;
        }

        let nextStep = true;

        if (!$this.address) {
          nextStep = $this._requestAccounts();
        }

        if (!nextStep) return;

        $this.connectedChainId = yield $this._getChainId();

        if ($this.connectedChainId !== WcChain.id) {
          nextStep = yield $this._switchNetwork();
        }

        return nextStep;
      } finally {
        $this._flowInProgress = null;
      }
    })
  );

  siweFlow = this.rootStore.singleCall(
    "siweFlow",
    flow(function* (rootStore: RootStore) {
      const $this = rootStore.ethereumStore;

      const {
        rootStore: {
          notificationStore: { addNotification },
        },
      } = $this;

      if ($this._flowInProgress) return;

      const connected = yield $this._ensureConnectedFlow();
      if (!connected) return connected;

      try {
        $this._flowInProgress = "siweFlow";
        $this.rootStore._lock++;

        let {
          payload: payload1,
          error: error1,
        }: TApiResponse<TSiweBeginResponse> = yield identityClient.beginSiwe(
          $this.address!
        );

        if (error1) {
          addNotification(error1);
          return;
        }

        $this.rootStore._lock--;

        $this.signingSiweToken = true;
        const signature = yield $this.sign(payload1!.token);
        $this.signingSiweToken = false;
        $this.rootStore._lock++;

        const {
          payload: payload2,
          error: error2,
        }: TApiResponse<TIdentityResponse> = yield identityClient.completeSiwe({
          ethAddress: $this.address!,
          signature,
          sessionId: payload1!.sessionId,
        });

        if (error2) {
          addNotification(error2);
          return;
        }

        if (!payload2!.token) {
          addNotification(payload2!.errors[0] ?? "Something went wrong");
        }

        const token = payload2!.token;
        const newUser = payload2!.newUser;
        const hasUser = yield $this.rootStore.userStore._setAccessToken(token);

        if (!hasUser) return;

        yield $this.rootStore.walletStore.loadWallet();
        yield when(() => $this.rootStore.walletStore.walletLoaded);

        const {
          rootStore: {
            walletStore: {
              client,
              hasBsvAccount,
              createPkAndSave,
              createEvmAccount,
              byTokenId,
            },
          },
        } = $this;

        if (!client) {
          addNotification("Server error");
          return;
        }

        yield consigliereClient.updateSetting(
          token,
          custodialWalletSetting,
          "true"
        );
        $this.rootStore.walletStore.settings[custodialWalletSetting] = "true";

        if (newUser) {
          if (!hasBsvAccount) {
            try {
              yield createPkAndSave();

              for (const { Network, Asset } of values(byTokenId)) {
                if (
                  Network !== "Any" &&
                  Network !== "Bsv" &&
                  Network !== "Tron" &&
                  Asset !== "Usdxs"
                ) {
                  yield createEvmAccount($this.address!, Network, Asset);
                }
              }

              // yield $this.rootStore.walletStore.loadWallet();
            } catch (error) {
              addNotification(
                error instanceof Error ? error.message : `${error}`
              );
            }
          }
        }
      } catch (error) {
        addNotification(unwrapError(error));
      } finally {
        $this._flowInProgress = null;
        $this.rootStore._lock--;
      }
    })
  );

  // sendTokenFlow = flow<string, [to: string, amount: number]>(function* (
  //   this: EthereumStore,
  //   to: string,
  //   amount: number
  // ) {
  //   const {
  //     rootStore: {
  //       bridgeStore: {
  //         BridgeAccount: { stablecoinType = StablecoinType.usdt },
  //       },
  //     },
  //   } = this;
  //   const token = tokensMap[stablecoinType!];

  //   const connected = yield this._ensureConnectedFlow();
  //   if (!connected) return;

  //   if (this._flowInProgress) return 0;

  //   this._flowInProgress = "sendTokenFlow";

  //   const result = yield this._userAction(async () => {
  //     const contract = this._getContract();

  //     const txId = await contract.write.transfer([
  //       to,
  //       toWei(amount, token.unit),
  //     ]);

  //     if (!isProduction) console.log(`Tx sent: ${txId}`);

  //     return txId;
  //   });

  //   this._flowInProgress = null;

  //   return result;
  // });

  _getContract = () => {
    // if (!this.contract) {
    //   const transport = custom(this.ethereum);
    //   const publicClient = createPublicClient({
    //     chain: chain,
    //     transport,
    //   });
    //   const walletClient = createWalletClient({
    //     account: getAddress(this.address!),
    //     chain,
    //     transport,
    //   });
    //   this.contract = getContract({
    //     address:
    //       tokensMap[this.rootStore.bridgeStore.BridgeAccount.stablecoinType!]
    //         .address,
    //     abi: transferAbi,
    //     publicClient,
    //     walletClient,
    //   });
    // }
    // return this.contract;
  };

  // refreshBalanceFlow = flow(function* (this: EthereumStore) {
  //   const {
  //     rootStore: {
  //       bridgeStore: {
  //         BridgeAccount: { stablecoinType, ethWithdrawAddress },
  //       },
  //     },
  //   } = this;
  //   if (this._flowInProgress) return 0;
  //   this._flowInProgress = "refreshBalanceFlow";
  //   const token = tokensMap[stablecoinType!];
  //   try {
  //     const publicClient = createPublicClient({
  //       chain: chain,
  //       transport: http(),
  //     });
  //     const contract = getContract({
  //       address: tokensMap[stablecoinType!].address,
  //       abi: balanceOfAbi,
  //       publicClient,
  //     });
  //     const balance: number = yield contract.read.balanceOf([
  //       this.address || ethWithdrawAddress,
  //     ]);
  //     const tokenBalance = +fromWei(balance, token.unit);
  //     this._balance = tokenBalance;
  //     return tokenBalance;
  //   } catch (error) {
  //     this._handleError(error);
  //     return 0;
  //   } finally {
  //     this._flowInProgress = null;
  //   }
  // });

  sign = flow<string, [string]>(function* (
    this: EthereumStore,
    message: string
  ) {
    const { wagmiConfig } = getOrCreateEthClient();
    // const note = `0x${Buffer.from(message, "utf8").toString("hex")}`;

    return yield signMessage(wagmiConfig, { message });

    // return yield this._userAction(() =>
    //   // @ts-ignore
    //   ethereum.request<string>({
    //     method: "personal_sign",
    //     params: [`0x${Buffer.from(message, "utf8").toString("hex")}`, address],
    //   })
    // );
  });

  _userAction = flow(function* <T>(
    this: EthereumStore,
    action: () => Promise<T>
  ) {
    // if (this.state === State.pending) {
    //   this.rootStore.notificationStore.addNotification(
    //     "Wallet is awaiting for your action"
    //   );

    //   return;
    // }

    this.state = State.pending;

    try {
      const {
        rootStore: {
          notificationStore: { addNotification },
        },
        isInjectedProvider,
      } = this;

      if (!isInjectedProvider) {
        const timeout = setTimeout(() => {
          addNotification("Check your wallet application");
        }, 5000);

        let disposer: IReactionDisposer | null = null;
        const cancelNotification = () => {
          clearTimeout(timeout);
          disposer!();

          document.removeEventListener("visibilitychange", cancelNotification);
          window.removeEventListener("focus", cancelNotification);
        };

        disposer = when(() => this.state !== State.pending, cancelNotification);

        document.addEventListener("visibilitychange", cancelNotification);
        window.addEventListener("focus", cancelNotification);
      }

      const result = yield action();

      this.state = State.success;

      return result;
    } catch (error) {
      this._handleError(error);
    }

    return undefined;
  });

  _requestAccounts = () => {
    const { wagmiConfig } = getOrCreateEthClient();
    const { address } = getAccount(wagmiConfig);

    this.address = address;

    // const accs: string[] = yield this.ethereum.request<string[]>({
    //   method: "eth_requestAccounts",
    // });

    // if (accs.length > 0) {
    //   this.address = accs[0];
    //   return true;
    // }

    return address === this.address;
  };

  _getChainId = () => {
    const { wagmiConfig } = getOrCreateEthClient();
    return getChainId(wagmiConfig);

    // const chainId: string = yield this.ethereum.request<string>({
    //   method: "net_version",
    //   params: [],
    // });

    // return Number(chainId);
  };

  _switchNetwork = flow(function* (this: EthereumStore) {
    this.state = State.pending;

    const {
      rootStore: {
        notificationStore: { addNotification },
      },
    } = this;

    try {
      addNotification("Need to switch network");

      const { wagmiConfig } = getOrCreateEthClient();
      yield switchChain(wagmiConfig, { chainId: WcChain.id });

      // yield this.ethereum.request({
      //   method: "wallet_switchEthereumChain",
      //   params: [{ chainId: toHexChainId(WcChain.id) }],
      // });

      this.state = State.success;
    } catch (error) {
      this._handleError(error);
      this.state = State.error;
      return false;
    }

    return true;
  });

  _handleError = (error: unknown) => {
    const {
      rootStore: {
        notificationStore: { addNotification },
      },
    } = this;

    if (isJsonRpcError(error)) {
      if (error.message.startsWith("Unrecognized chain ID")) {
        return true;
      }

      if (
        error.code === errorCodes.provider.userRejectedRequest ||
        error.code === errorCodes.rpc.internal
      ) {
        this.state = State.rejected;
        console.log("User rejected");

        addNotification("Canceled");
        return false;
      }

      if (error.code === errorCodes.rpc.resourceUnavailable) {
        this.state = State.pending;
        console.log(`Wallet awaits users action; ${error.message}`);
        addNotification("Wallet is awaiting for your action");
        return false;
      }
    } else if (isTransactionExecutionError(error)) {
      if (error.shortMessage === "User rejected the request.") {
        this.state = State.rejected;
        console.log("User rejected");

        addNotification("Canceled");
        return false;
      }
    }

    addNotification(unwrapError(error));

    return false;
  };

  _attachHandlers = () => {
    const { wagmiConfig } = getOrCreateEthClient();
    const account = getAccount(wagmiConfig);
    const connector = account.connector!;

    connector.emitter.on("connect", this._handleConnect);
    connector.emitter.on("disconnect", this._handleConnect);
    connector.emitter.on("change", this._handleAccountsChanged);
  };

  _dettachHandlers = () => {
    const { wagmiConfig } = getOrCreateEthClient();
    const account = getAccount(wagmiConfig);
    const connector = account.connector!;

    connector.emitter.off("connect", this._handleConnect);
    connector.emitter.off("disconnect", this._handleConnect);
    connector.emitter.off("change", this._handleAccountsChanged);
  };

  _handleConnect = (connectInfo: TEmitterEvent) => {
    console.warn(connectInfo);

    this.isProviderConnected = true;
  };

  _handleDisconnect = (error: unknown) => {
    console.warn(error);

    this.isProviderConnected = false;
  };

  _handleAccountsChanged = async ({ accounts }: TEmitterEvent) => {
    console.warn("Accounts changed");

    if (isArrayOfStrings(accounts)) {
      this.address = accounts[0];
      this.connectedChainId = await this._getChainId();
    } else {
      console.log(accounts);
    }
  };

  _handleChainChanged = (chainId: unknown) => {
    console.warn("Network changed");

    if (typeof chainId === "string") {
      this.connectedChainId = Number(chainId);
    } else {
      console.log(chainId);
    }
  };
}

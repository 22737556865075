import { createBrowserHistory } from "history";
import { TAllNetworks, TCryptoAsset, TDirection } from "src/types.enums";

export const history = createBrowserHistory({ window });

export const routes = {
  root: {
    path: "/",
    signIn: {
      path: "/sign-in",
    },
    signUp: {
      path: "/sign-up",
    },
    passwordReset: {
      init: {
        path: "/password-reset/init",
      },
      complete: {
        path: "/password-reset/complete",
      },
    },
    ethereumConnect: {
      path: "/ethereum",
    },
  },
  wallet: {
    path: "/wallet/",

    setup: {
      path: "/wallet/setup",
      mnemonic: {
        new: {
          path: "/wallet/setup/mnemonic/new",
        },
        confirm: {
          path: "/wallet/setup/mnemonic/confirm",
        },
        validate: {
          path: "/wallet/setup/mnemonic/validate",
        },
      },
      connectApp: {
        path: "/wallet/setup/connect-app",
      },
    },

    money: {
      path: "/wallet/:direction/",
      uri: (direction: TDirection) => `/wallet/${direction}`,

      address: {
        path: "/wallet/:direction/:from/:to/:asset/:assetChain/address",
        uri: (
          direction: TDirection,
          from: TAllNetworks,
          to: TAllNetworks,
          asset: TCryptoAsset,
          assetChain: TAllNetworks
        ) =>
          `/wallet/${direction}/${from.toLowerCase()}/${to.toLowerCase()}/${asset.toLowerCase()}/${assetChain.toLowerCase()}/address`,
      },
      amount: {
        path: "/wallet/:direction/:from/:to/:asset/:assetChain/amount",
        uri: (
          direction: TDirection,
          from: TAllNetworks,
          to: TAllNetworks,
          asset: TCryptoAsset,
          assetChain: TAllNetworks
        ) =>
          `/wallet/${direction}/${from.toLowerCase()}/${to.toLowerCase()}/${asset.toLowerCase()}/${assetChain.toLowerCase()}/amount`,
      },
      confirm: {
        path: "/wallet/:direction/:from/:to/:asset/:assetChain/confirm",
        uri: (
          from: TAllNetworks,
          to: TAllNetworks,
          asset: TCryptoAsset,
          assetChain: TAllNetworks
        ) =>
          `/wallet/withdraw/${from.toLowerCase()}/${to.toLowerCase()}/${asset.toLowerCase()}/${assetChain.toLowerCase()}/confirm`,
      },
      success: {
        path: "/wallet/:direction/:from/:to/:asset/:assetChain/success",
        uri: (
          from: TAllNetworks,
          to: TAllNetworks,
          asset: TCryptoAsset,
          assetChain: TAllNetworks
        ) =>
          `/wallet/withdraw/${from.toLowerCase()}/${to.toLowerCase()}/${asset.toLowerCase()}/${assetChain.toLowerCase()}/success`,
      },
    },
  },
};

import { flow, makeAutoObservable, reaction } from "mobx";
import { RootStore } from "./rootStore";
import {
  Address,
  FeeRate,
  Mnemonic,
  OutPoint,
  StasBundleFactory,
  TFundingUtxoRequest,
  TStasPayoutBundle,
  TransactionBuilder,
  Wallet,
} from "dxs-stas-sdk";
import { TEstimateResponse, consigliereClient } from "src/clients";
import { clearPersistedStore, makePersistable } from "mobx-persist-store";
import { isDevelopment, isStaging, walletService } from "src/services";
import { TContact } from "src/types";
import { BsvTokenId } from "src/types.enums";

export class TransactionStore {
  withdrawCost?: TEstimateResponse;

  _recipient: TContact | null = null;
  _amountToSend: number | null = null;
  _bundleToSend: TStasPayoutBundle | null = null;
  _transctionToSend: TransactionBuilder | null = null;
  _batchRequestId: string | null = null;

  _amountToWithdraw: number | null = null;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    makePersistable(this, {
      storage: window.localStorage,
      stringify: true,
      name: "TransactionStore",
      properties: ["withdrawCost"],
    });

    reaction(() => this.rootStore.userStore.hasUser, this._onHasUserChanged);
  }

  setRecipient = (recipient: TContact | null) => {
    this._recipient = recipient;
  };

  setAmountToSend = (amount: number | null) => {
    this._amountToSend = amount;
  };

  setAmountToWithdraw = (amount: number | null) => {
    this._amountToWithdraw = amount;
  };

  setBundleToSend = (bundle: TStasPayoutBundle | null) => {
    this._bundleToSend = bundle;
  };
  setTransactionToSend = (transaction: TransactionBuilder | null) => {
    this._transctionToSend = transaction;
  };

  get recipient() {
    return this._recipient;
  }

  get amountToSend() {
    return this._amountToSend;
  }

  get amountToWithdraw() {
    return this._amountToWithdraw;
  }

  get bundleToSend() {
    return this._bundleToSend;
  }
  get transactionToSend() {
    return this._transctionToSend;
  }

  prepareBsvTransaction = this.rootStore.blockingCall(
    flow(function* (
      root: RootStore,
      amount: number,
      to: Address,
      note?: Buffer[]
    ) {
      const satoshis = Math.round(amount * 100_000_000);
      const {
        walletStore: { getMnemonicOrThrow, createBsvWallets },
        userStore: { AccessToken },
      } = root;
      const mnemonic: Mnemonic = yield getMnemonicOrThrow();
      const [main]: Wallet[] = yield createBsvWallets(mnemonic!);
      const utxos: OutPoint[] = yield walletService.getUtxoSet(
        AccessToken,
        main.Address.Value
      );
      const txBuilder = TransactionBuilder.init().addP2PkhOutput(satoshis, to);

      if (note) txBuilder.addNullDataOutput(note);

      let accumulated = 0;

      for (const utxo of utxos) {
        txBuilder.addInput(utxo, main);

        const fee = txBuilder.getFee(FeeRate);

        accumulated += utxo.Satoshis;

        if (accumulated > satoshis + fee) break;
      }

      return txBuilder
        .addChangeOutputWithFee(main.Address, accumulated - satoshis, FeeRate)
        .sign();
    })
  );

  prepareStasBundle = this.rootStore.blockingCall(
    flow(function* (
      root: RootStore,
      tokenId: string,
      amount: number,
      to: Address,
      note?: Buffer[],
      estimateWithdraw?: boolean
    ) {
      const $this = root.transactionStore;
      const walletStore = root.walletStore;
      const {
        getFundingUtxo,
        getStasUtxoSet,
        getTransactions,
        rootStore: {
          userStore: { AccessToken },
        },
      } = $this;

      const { getTokenScheme, getMnemonicOrThrow, createBsvWallets } =
        walletStore;
      const { tokenScheme, cryptoAssetKey } = getTokenScheme(tokenId);
      const { withdrawVia } = walletStore.getTokenId(
        cryptoAssetKey.Network,
        cryptoAssetKey.Asset
      );
      const amountSatoshis = Math.round(amount * tokenScheme.SatoshisPerToken);
      const mnemonic = yield getMnemonicOrThrow();

      const [main, funding]: Wallet[] = yield createBsvWallets(mnemonic!);
      const factory = new StasBundleFactory(
        tokenScheme,
        main,
        funding,
        (request) => getFundingUtxo(funding, to, amountSatoshis, request),
        () => getStasUtxoSet(tokenId, main),
        getTransactions
      );

      const bundle: TStasPayoutBundle = yield factory.createBundle(
        amountSatoshis,
        to,
        note
      );

      if (isDevelopment || isStaging) {
        if (bundle.devMessage) throw new Error(bundle.devMessage);
      } else {
        if (bundle.message) throw new Error(bundle.message);
      }

      if (estimateWithdraw) {
        if (withdrawVia.evmNetwork === "Bsv")
          throw Error("Unsupported network");

        const { payload: cost } = yield consigliereClient.estimateWithdrawFee(
          AccessToken,
          withdrawVia.evmNetwork,
          withdrawVia.cryptoAsset
        );

        $this.withdrawCost = cost;
      }

      return bundle;
    })
  );

  getFundingUtxo = flow(function* (
    this: TransactionStore,
    feeWallet: Wallet,
    to: Address,
    amountSatoshis: number,
    request: TFundingUtxoRequest
  ) {
    const {
      rootStore: {
        userStore: { AccessToken },
      },
    } = this;

    const { utxo, requestId }: { utxo: OutPoint; requestId: string } =
      yield walletService.getFundingUtxo(AccessToken, feeWallet.Address.Value, {
        to: to.Value,
        amountSatoshis,
        ...request,
      });

    this._batchRequestId = requestId;

    return utxo;
  });

  getStasUtxoSet = (tokenId: string, stasWallet: Wallet) => {
    const {
      rootStore: {
        userStore: { AccessToken },
        walletStore: { getTokenScheme },
      },
    } = this;

    const { tokenScheme } = getTokenScheme(tokenId);
    return walletService.getUtxoSet(
      AccessToken,
      stasWallet.Address.Value,
      tokenScheme
    );
  };

  broadcast = this.rootStore.blockingCall(
    flow(function* (root: RootStore, tokenId?: string) {
      const $this = root.transactionStore;
      const {
        rootStore: {
          userStore: { AccessToken },
        },
        bundleToSend,
        transactionToSend,
      } = $this;

      if (tokenId !== BsvTokenId) {
        if (bundleToSend === null || !bundleToSend.transactions?.length)
          throw Error("Create stast bundle first");

        const { error } = yield consigliereClient.broadcastBatchSend(
          AccessToken,
          {
            rawTransactions: bundleToSend.transactions,
            requestId: $this._batchRequestId!,
          }
        );

        if (error) throw Error(error);
      } else {
        if (!transactionToSend) throw Error("Create transaction first");

        const { error } = yield consigliereClient.broadcast(
          AccessToken,
          transactionToSend.toHex()
        );

        if (error) throw Error(error);
      }
    })
  );

  getTransactions = (ids: Array<string>) => {
    const {
      rootStore: {
        userStore: { AccessToken },
      },
    } = this;

    return walletService.getTransactions(AccessToken, ids);
  };

  clear = flow(function* (this: TransactionStore) {
    this.withdrawCost = undefined;

    yield clearPersistedStore(this);
  });

  _onHasUserChanged = flow(function* (
    this: TransactionStore,
    hasUser: boolean,
    prevValue: boolean
  ) {
    if (!hasUser && prevValue) yield this.clear();
  });

  // // @ts-ignore
  // window.prep = async (amount: number, to: string) => {
  //   const bundle = await this.prepareStasBundle(
  //     amount,
  //     Address.fromBase58(to)
  //   );
  //   this.setBundleToSend(bundle);

  //   return bundle;
  // };
  // // @ts-ignore
  // window.broad = this.broadcast;
  // // @ts-ignore
  // window.send = async (amount: number, to: string) => {
  //   // @ts-ignore
  //   await window.prep(amount, to); // @ts-ignore
  //   await window.broad();
  // };
  // // @ts-ignore
  // window.sendN = async (n: number, amount: number, to: string) => {
  //   for (let i = 0; i < n; i++) {
  //     // @ts-ignore
  //     await window.send(amount, to);
  //   }
  // };
}

import { consigliereHost } from "src/services";
import { call } from "./client-helper";
import { TClientSettingName, TCryptoAsset, TEvmNetwork } from "src/types.enums";
import {
  TBroadcastAttempt,
  TBsvAccount,
  TClient,
  TContact,
  TDepositStatus,
  TEvmAccount,
  TTokenSchema,
  TWallet,
} from "src/types";

const baseUrl = `${consigliereHost}/api`;

export type TPageRequest = {
  skip: number;
  take: number;
};

export type TOrderedPageRequest = {
  desc: boolean;
} & TPageRequest;

// ======================== Client API ============================

export type TAddContactRequest = {
  address: string;
  name?: string;
};

const getClient = (accessToken: string) =>
  call<TClient>({
    path: `${baseUrl}/client`,
    method: "GET",
    accessToken,
  });

const addContact = (accessToken: string, data: TAddContactRequest) =>
  call<TContact>({
    path: `${baseUrl}/client/contact`,
    method: "POST",
    data,
    accessToken,
  });

const getContacts = async (
  accessToken: string,
  { skip, take, desc }: TOrderedPageRequest
) => {
  const params = new URLSearchParams();

  params.append("skip", skip.toString());
  params.append("take", take.toString());
  params.append("desc", desc.toString());

  return await call<TContact[]>({
    path: `${baseUrl}/client/contacts?${params.toString()}`,
    method: "GET",
    accessToken,
  });
};

const updateSetting = async (
  accessToken: string,
  type: TClientSettingName,
  value: string
) =>
  call<null>({
    path: `${baseUrl}/client/setting`,
    method: "POST",
    data: { type, value },
    accessToken,
  });

const getSetting = async (accessToken: string, type: TClientSettingName) =>
  call<string | null>({
    path: `${baseUrl}/client/setting/${type}`,
    method: "GET",
    accessToken,
  });

// ======================== Bridge Account API ========================

export type TCreateAccountRequest = {
  evmNetwork: TEvmNetwork;
  cryptoAsset: TCryptoAsset;
  withdrawAddress: string;
};

export type TEstimateResponse = {
  feeUnitPriceCrypto: number;
  feeUnitsUsed: number;
  cryptoUsdRate: number;
  feePriceCrypto: number;
  feePriceUsd: number;
};

export type TAvailableBalanceResponse = {
  amount: number;
  withdrawLimit: number;
  withdrawPeriodStartedSeconds: number;
};

export type TAssetConfig = {
  name: string;
  tokenId: string;
  symbol: string;
  satoshisPerToken: number;
};

export type TBridgeConfig = {
  assets: { [network: string]: { [asset: string]: TTokenSchema } }; // Record<string, Record<string, TTokenSchema>>;
  bountyTokenId: string;
};

const getBridgeConfig = () =>
  call<TBridgeConfig>({
    path: `${baseUrl}/bridge/configuration`,
    method: "GET",
  });

const getWallet = (accessToken: string) =>
  call<TWallet>({
    path: `${baseUrl}/wallet`,
    method: "GET",
    accessToken,
  });

const createBsvAccount = (
  accessToken: string,
  address: string,
  fundingAddress: string
) =>
  call<TBsvAccount>({
    path: `${baseUrl}/wallet/create-bsv-account`,
    method: "POST",
    accessToken,
    data: { address, fundingAddress },
  });

const createEvmAccount = (accessToken: string, data: TCreateAccountRequest) =>
  call<TEvmAccount>({
    path: `${baseUrl}/wallet/create-evm-account`,
    method: "POST",
    accessToken,
    data,
  });

const grantAccess = (accessToken: string) =>
  call<string>({
    path: `${baseUrl}/wallet/grant-access`,
    method: "POST",
    accessToken,
  });

const getAvailableBalance = (
  accessToken: string,
  evmNetwork: TEvmNetwork,
  cryptoAsset: TCryptoAsset
) =>
  call<TAvailableBalanceResponse>({
    path: `${baseUrl}/bridge/available-balance/${evmNetwork}/${cryptoAsset}`,
    method: "GET",
    accessToken,
  });

const estimateWithdrawFee = (
  accessToken: string,
  evmNetwork: TEvmNetwork,
  cryptoAsset: TCryptoAsset
) =>
  call<TEstimateResponse>({
    path: `${baseUrl}/bridge/estimate-withdraw-fee/${evmNetwork}/${cryptoAsset}`,
    method: "GET",
    accessToken,
  });

const estimateDepositFee = (
  accessToken: string,
  evmNetwork: TEvmNetwork,
  cryptoAsset: TCryptoAsset
) =>
  call<TEstimateResponse>({
    path: `${baseUrl}/bridge/estimate-deposit-fee/${evmNetwork}/${cryptoAsset}`,
    method: "GET",
    accessToken,
  });

// ======================== Transactions API ==========================

export type TTransactionsResponse = {
  [txId: string]: string;
};

export type TBatchBroadcastInitRequest = {
  to: string;
  amountSatoshis: number;
  utxoIdsToSpend: string[];
  estimatedFeeSatoshis: number;
  transactionsCount: number;
};
export type TBatchBroadcastInitResponse = {
  requestId: string;
  feeTxId: string;
  feeVout: number;
};

export type TBatchBroadcastSendRequest = {
  requestId: string;
  rawTransactions: string[];
};

const broadcast = (accessToken: string, transaction: string) =>
  call<TBroadcastAttempt[]>({
    path: `${baseUrl}/tx/broadcast/${transaction}`,
    method: "POST",
    accessToken,
    compress: true,
  });

const broadcastBatch = (accessToken: string, transactions: string[]) =>
  call<TBroadcastAttempt[]>({
    path: `${baseUrl}/tx/batch/broadcast`,
    method: "POST",
    accessToken,
    data: transactions,
    compress: true,
  });

const broadcastBatchInit = (
  accessToken: string,
  request: TBatchBroadcastInitRequest
) =>
  call<TBatchBroadcastInitResponse>({
    path: `${baseUrl}/tx/batch/broadcast/init`,
    method: "POST",
    accessToken,
    data: request,
    compress: true,
  });

const broadcastBatchSend = (
  accessToken: string,
  request: TBatchBroadcastSendRequest
) =>
  call<null>({
    path: `${baseUrl}/tx/batch/broadcast/send`,
    method: "POST",
    accessToken,
    data: request,
    compress: true,
  });

const getTransactions = (accessToken: string, transactionIds: string[]) => {
  const params = new URLSearchParams();

  for (const id of transactionIds) {
    params.append("ids", id);
  }

  return call<TTransactionsResponse>({
    path: `${baseUrl}/tx/batch/get?${params.toString()}`,
    method: "GET",
    accessToken,
  });
};

// ======================== Address API ===============================

export type TUtxoSetRequest = {
  address: string;
  tokenId?: string;
  withTransactions?: boolean;
};

export type TUtxoSetResponse = {
  utxoSet: {
    id: string;
    txId: string;
    vout: number;
    height: number;
    address: string;
    tokenId: string | null;
    satoshis: number;
  }[];
  transactions?: {
    [id: string]: string;
  };
};

export type TBalanceResponse = {
  address: string;
  tokenId: string | null;
  satoshis: number;
}[];

const getBalances = (
  accessToken: string,
  addresses: string[],
  tokenIds: string[]
) => {
  return call<TBalanceResponse>({
    path: `${baseUrl}/address/v2/balance`,
    method: "POST",
    accessToken,
    data: { addresses, tokenIds },
  });
};

const getUtxoSet = (accessToken: string, data: TUtxoSetRequest) =>
  call<TUtxoSetResponse>({
    path: `${baseUrl}/address/v2/utxo-set`,
    method: "POST",
    accessToken,
    data,
  });

// ======================== History API ===============================

export type TBsvHistoryRequest = {
  address: string;
  tokenIds: string[];
  skipZeroBalance: boolean;
  withMeta: boolean;
  withPendingDeposit: boolean;
} & TOrderedPageRequest;

export type TDepositRef = {
  isBridgeSetup: boolean;
  isEthDeposit: boolean;
  ethTxId: string;
  evmAddress: string;
  evmNetwork: TEvmNetwork;
  amount: number;
  bounty: number;
  fee: number;
};

export type TBsvHistoryItem = {
  address: string;
  tokenId?: string;
  txId: string;
  timestamp: number;
  height: number;
  validStasTx: boolean;
  spentSatoshis: number;
  receivedSatoshis: number;
  balanceSatoshis: number;
  txFeeSatoshis: number;
  note: string;
  fromAddresses: string[];
  toAddresses: string[];
  depositRef: TDepositRef;
  bsvRate: number;
};

export type TBsvHistoryResponse = {
  history: TBsvHistoryItem[];
  pendingDeposit?: TDepositStatus;
  totalCount: number;
};

const getBsvHistory = (accessToken: string, data: TBsvHistoryRequest) =>
  call<TBsvHistoryResponse>({
    path: `${baseUrl}/address/v2/detailed-history`,
    method: "POST",
    accessToken,
    data,
    withCors: true,
  });

export const consigliereClient = {
  getClient,
  addContact,
  getContacts,
  updateSetting,
  getSetting,
  getBridgeConfig,
  getWallet,
  createBsvAccount,
  createEvmAccount,
  grantAccess,
  getAvailableBalance,
  estimateWithdrawFee,
  estimateDepositFee,
  broadcast,
  broadcastBatch,
  broadcastBatchInit,
  broadcastBatchSend,
  getTransactions,
  getBalances,
  getUtxoSet,
  getBsvHistory,
};
